import { TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFoldersSelector } from '../../store/selectors/folders.selector';
import { useSongSelector } from '../../store/selectors/song.selector';

export type SongNameProps = {
  canEdit: boolean;
  setHandleRename: (handleRename: () => void) => void;
};

export function SongName({ canEdit }: SongNameProps) {
  const { folderId, songId } = useParams();
  const { song, songVersions, currentVersion, renameSong, renameVersionWithoutSongTrack } = useSongSelector();
  const [inputName, setInputName] = useState('');
  const { renameSongInSidebar } = useFoldersSelector();
  const renameSongInputRef = useRef<null | HTMLInputElement>(null);
  let songName = songVersions.find(v => v.id === currentVersion)?.songName;

  useEffect(() => {
    if (songName) {
      setInputName(songName);
    } else if (song?.name) {
      setInputName(song?.name);
    } else {
      setInputName('');
    }
  }, [song, songName]);

  const handleOnChangeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setInputName(input);
  };

  const handleKeyDownOnSongRename = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.preventDefault();
      renameSongInputRef.current?.blur();
    }
  };

  return (
    <TextField
      variant="standard"
      value={inputName}
      sx={{
        fontWeight: 400,
        fontSize: { xs: '26px', sm: '32px' },
        lineHeight: { xs: '34px', sm: '40px' },
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '& .MuiInputBase-input': {
          fontSize: { xs: '26px', sm: '32px' },
          lineHeight: { xs: '34px', sm: '40px' },
          padding: '0',
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          color: '#FFFF',
          transition: 'none',
          caretColor: '#FFFF'
        },
        '.MuiInput-underline:before, .MuiInput-underline:after': {
          borderBottom: 'none !important'
        },
        '.MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: 'none !important'
        },
        '.MuiInput-underline:focus:before': {
          borderBottom: 'none !important'
        }
      }}
      fullWidth
      inputRef={renameSongInputRef}
      onKeyDown={handleKeyDownOnSongRename}
      onBlur={async e => {
        const newName = renameSongInputRef.current?.value;
        if (currentVersion === songVersions.sort((a, b) => a.versionNumber - b.versionNumber).at(songVersions.length - 1)?.id) {
          await renameSongInSidebar(folderId!, songId!, newName ?? song?.name!);
        }

        if (!song) {
          await renameVersionWithoutSongTrack(newName ?? '');
        } else {
          renameSong(newName ?? song?.name!);
        }
      }}
      onChange={handleOnChangeName}
      disabled={!canEdit}
      placeholder="Song title"
    />
  );
}
