import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { DialogCloseButton } from '../../../components/DialogCloseButton';
import { useFoldersSelector } from '../../../store/selectors/folders.selector';
import { errorMessage } from '../../../utils/errorMessage';

type CreateFolderDialogProps = {
  open: boolean;
  onClose: (event?: any, reason?: any) => void;
};

export default function CreateFolderDialog({ open, onClose }: CreateFolderDialogProps) {
  const { createFolder } = useFoldersSelector();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object({
    folderName: Yup.string().required('Folder Name is required')
  });

  const formik = useFormik({
    initialValues: {
      folderName: ''
    },
    validationSchema: validationSchema,
    onSubmit: async ({ folderName }) => {
      await createFolder(folderName);

      enqueueSnackbar('Folder created successfully', { variant: 'success' });

      onClose();
    }
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xs'} disableRestoreFocus>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <DialogCloseButton onClose={onClose} />
          <DialogTitle>Create folder</DialogTitle>
          <DialogContent dividers>
            <TextField
              label="Folder name"
              margin="dense"
              fullWidth
              {...formik.getFieldProps('folderName')}
              {...errorMessage(formik.touched.folderName, formik.errors.folderName)}
              inputRef={el => {
                el?.focus();
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={!(formik.dirty && formik.isValid)} type="submit" size="small" variant="contained" color="info">
              Create
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
