import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Folder, SidebarFolder } from '../../../@types/folders';
import { useFoldersSelector } from '../../../store/selectors/folders.selector';
import DeleteFolderDialog from '../Dialogs/DeleteFolderDialog';
import FolderDropDownItemsList from './FolderDropDownItemsList';
import LinkIcon from '@mui/icons-material/Link';
import SharingFolderDialog from '../../../components/SharingFolderDialog';
import { useSongSelector } from '../../../store/selectors/song.selector';

type FolderDropDownProps = {
  folder: SidebarFolder;
  setMovingSongToFolder: React.Dispatch<React.SetStateAction<null | { folderName: string; songName: string }>>;
  highlightedSongId: string;
  setHighlightedSongId: (songId: string) => void;
  isSharingFolder?: boolean;
  isPublicLocation?: boolean;
  level?: number;
};

export default function FolderDropDown({
  folder,
  setMovingSongToFolder,
  setHighlightedSongId,
  highlightedSongId,
  isSharingFolder = false,
  isPublicLocation = false,
  level = 0
}: FolderDropDownProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [folderIdForDelete, setFolderIdForDelete] = useState<string>('');
  const [folderIdForRename, setFolderIdForRename] = useState<string>('');
  const renameInputRef = useRef<null | HTMLInputElement>(null);
  const [isSharingFolderDialogOpen, setIsSharingFolderDialogOpen] = useState<boolean>(false);
  const screenWidth = window.innerWidth;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { renameFolder, deleteFolder, moveFolderSong, isRenameLoading, sharedFolderUsers, getFolderSharedUsers, toggleFolder, moveFolder } =
    useFoldersSelector();

  useEffect(() => {
    renameInputRef.current?.focus();
  }, [folderIdForRename]);

  const handleOpenFolderMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (isSharingFolder && folder.accessType !== 'EDITOR') return;

    setAnchorEl(e.currentTarget);
    getFolderSharedUsers(folder.id);
  };

  const handleCloseFolderMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenFolderDeleteDialog = (folderId: string) => {
    handleCloseFolderMenu();
    setFolderIdForDelete(folderId);
  };

  const handleCloseFolderDeleteDialog = () => {
    setFolderIdForDelete('');
  };

  const handleRename = (folderId: string) => {
    handleCloseFolderMenu();
    setFolderIdForRename(folderId);
  };

  const handleShareFolder = (folderId: string) => {
    handleCloseFolderMenu();
    setIsSharingFolderDialogOpen(true);
  };

  const handleRenameOnBlur = () => {
    setFolderIdForRename('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      renameFolder(folder.id, renameInputRef.current?.value ?? folder.name).then(() => {
        enqueueSnackbar('Folder renamed successfully', { variant: 'success' });
      });

      handleRenameOnBlur();
    }
  };

  const handleDropMusicToFolder = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (isSharingFolder && folder.accessType !== 'EDITOR') return;

    const draggedData = JSON.parse(e.dataTransfer.getData('text/plain'));

    if (draggedData.folderId === folder.id) return;

    if (!!draggedData.folderName) {
      await moveFolder(draggedData.folderId, folder.id);
    } else if (!!draggedData.songName) {
      setMovingSongToFolder({ folderName: folder.name, songName: draggedData.songName });

      await moveFolderSong({ songId: draggedData.songId, currentFolderId: draggedData.folderId, newFolderId: folder.id });

      enqueueSnackbar('Song moved successfully', { variant: 'success' });
      setMovingSongToFolder(null);
    }
  };

  return (
    <>
      {isSharingFolderDialogOpen && (
        <SharingFolderDialog
          folderId={folder.id}
          folderName={folder.name}
          isOwner={true}
          sharedFolderUsers={sharedFolderUsers}
          onClose={() => setIsSharingFolderDialogOpen(false)}
          open={isSharingFolderDialogOpen}
          ownerEmail={folder.user?.email}
        />
      )}
      <DeleteFolderDialog
        open={Boolean(folderIdForDelete)}
        folder={folder}
        onClose={handleCloseFolderDeleteDialog}
        onConfirm={() => {
          deleteFolder(folder.id).then(() => {
            enqueueSnackbar('Folder deleted successfully', { variant: 'success' });
          });
          handleCloseFolderDeleteDialog();
          navigate('/dashboard');
        }}
      />
      <Accordion
        expanded={folder.isOpen}
        sx={{ boxShadow: 'none', '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: '56px !important' } }}
        onChange={() => {
          toggleFolder(folder.id);
        }}
      >
        <AccordionSummary
          draggable={!isSharingFolder && folder.id !== 'shared_folder'}
          onDragStart={e => {
            if (isSharingFolder) return;

            e.dataTransfer.setData('text/plain', JSON.stringify({ folderId: folder.id, folderName: folder.name }));
          }}
          onDrop={handleDropMusicToFolder}
          onDragOver={e => e.preventDefault()}
          sx={{
            backgroundColor: '#151515',
            p: 0,
            '& .MuiAccordionSummary-content': { m: '0 !important' },
            '& .MuiAccordionSummary-content.isExpanded': { m: '0 !important' },
            margin: 0,
            paddingRight: isSharingFolder && level > 0 ? '16px' : 0
          }}
          onContextMenu={handleOpenFolderMenu}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${folder.id}-content`}
          id={`${folder.id}-header`}
        >
          <Stack direction="row" spacing={2} alignItems="center" maxWidth={'100% !important'}>
            <Box
              width="48px"
              height="48px"
              position="relative"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: folder.isOpen ? 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 225, 245, 0.85) 88.47%)' : '#4B4B4B66',
                borderRadius: 1,
                margin: 0
              }}
            >
              {folder.id !== 'shared_folder' ? (
                <FolderOutlinedIcon fontSize="medium" sx={{ position: 'absolute' }} />
              ) : (
                <PersonAddOutlinedIcon fontSize="medium" sx={{ position: 'absolute' }} />
              )}
            </Box>
            {folderIdForRename ? (
              <TextField
                size="small"
                defaultValue={folder.name}
                inputRef={renameInputRef}
                onKeyDown={handleKeyDown}
                onBlur={handleRenameOnBlur}
                sx={{ fontSize: '14px' }}
              />
            ) : (
              <Tooltip title={folder.name.length > 16 ? folder.name : ''}>
                <Typography
                  sx={{
                    '&.MuiTypography-root': {
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: isMobile ? '210px' : `${Math.round((screenWidth / 800) * 100)}px`,
                      fontSize: '14px'
                    }
                  }}
                >
                  {folder.name} {isRenameLoading === folder.id && 'Loading....'}
                </Typography>
              </Tooltip>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: '#151515' }}>
          {!!folder.children.length && (
            <Grid container direction={'column'} mb={1} spacing={1}>
              {folder.children.map(child => {
                return (
                  <Grid item key={child.id + child.name}>
                    <FolderDropDown
                      folder={child}
                      setMovingSongToFolder={setMovingSongToFolder}
                      highlightedSongId={highlightedSongId}
                      setHighlightedSongId={setHighlightedSongId}
                      isSharingFolder={isSharingFolder}
                      isPublicLocation={isPublicLocation}
                      level={level + 1}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
          <FolderDropDownItemsList
            folder={folder}
            setHighlightedSongId={setHighlightedSongId}
            highlightedSongId={highlightedSongId}
            isSharingFiles={isSharingFolder}
            isPublicLocation={isPublicLocation}
          />
        </AccordionDetails>
      </Accordion>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseFolderMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              mt: 1.5
            }
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={() => handleShareFolder(folder.id)}>
          <LinkIcon sx={{ pr: 1 }} />
          Share folder
        </MenuItem>
        <MenuItem onClick={() => handleRename(folder.id)}>
          <DriveFileRenameOutlineIcon fontSize="small" sx={{ mr: 1 }} />
          Rename
        </MenuItem>
        <MenuItem sx={{ color: 'red' }} onClick={() => handleOpenFolderDeleteDialog(folder.id)}>
          <DeleteIcon fontSize="small" sx={{ mr: 1, color: 'red' }} />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}
