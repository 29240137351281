import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { AccessType, SharedUserInfo, SharedUserRoles } from '../@types/sharing';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';
import sharingService from '../services/sharingService';
import trackEvent from '../services/trackService';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUserStore } from '../store/user.store';
import axios from '../utils/axios';
import { errorMessage } from '../utils/errorMessage';
import { ValidationPatterns } from '../utils/validationPatterns';
import { DialogCloseButton } from './DialogCloseButton';

type SharingDialogProps = {
  songId?: string;
  folderId?: string;
  sharedSongsUsers?: SharedUserInfo[];
  onClose: () => void;
  open: boolean;
  isOwner: boolean;
  ownerEmail: string;
  showMsg: boolean;
  showSuccessMsg: boolean;
  toggleIsShareButtonClicked: () => void;
};

export default function SharingDialog({
  songId,
  folderId,
  sharedSongsUsers,
  onClose,
  open,
  isOwner,
  ownerEmail,
  showMsg,
  showSuccessMsg,
  toggleIsShareButtonClicked
}: SharingDialogProps) {
  const getUser = useUserStore(state => state.getUser);
  const {
    inviteUser,
    song,
    deleteSharedUser,
    accessShareType,
    accessTypePublic,
    setAccessShareType,
    setAccessTypePublic,
    getSharedUsers,
    inputShareType,
    setInputShareType
  } = useSongSelector();
  const [showPopup, setShowPopup] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth0();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const handleChange = async (event: SelectChangeEvent) => {
    await axios.post(`/songs/share-status/${songId}`, {
      isPublic: event.target.value === 'anyone',
      accessType: event.target.value === 'anyone' ? 'CO_OWNER' : null
    });

    setAccessShareType(event.target.value as string);
    setAccessTypePublic(event.target.value === 'anyone' ? 'CO_OWNER' : '');
  };

  const handleAccessChange = async (event: SelectChangeEvent) => {
    await axios.post(`/songs/share-status/${songId}`, {
      isPublic: accessShareType === 'anyone',
      accessType: event.target.value
    });

    setAccessTypePublic(event.target.value as string);
  };

  useEffect(() => {
    getUser();
    getSharedUsers(songId!);

    axios.get(`/songs/get-share-status/${songId}`).then(res => {
      setAccessShareType(res.data.isPublic ? 'anyone' : 'invited');
      setAccessTypePublic(res.data.accessType ?? '');
    });
  }, []);

  const handleSetAccessType = async (sharedUserEmail: string, accessType: string) => {
    await inviteUser({
      email: sharedUserEmail,
      songId: songId ?? '',
      songName: song?.name ?? '',
      folderId: folderId ?? '',
      type: accessType
    });
    trackEvent('share', { type: 'invite' });
    formik.resetForm();
  };

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').matches(ValidationPatterns.email, 'Please enter a valid email'),
    accessType: Yup.string().required().oneOf(Object.values(SharedUserRoles), 'Invalid access type')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      accessType: inputShareType
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      handleSetAccessType(values.email, values.accessType);
      toggleIsShareButtonClicked();

      formikHelpers.resetForm();
    }
  });

  const { touched, errors, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (params.get('email')?.length) {
      formik.setFieldValue('email', params.get('email'));
    }
  }, [location.search]);

  const handleCopySharingLink = async () => {
    const isFolderSharing = `isfs=${false}`;

    const urlPath =
      accessShareType === 'invited' ? `/dashboard/${folderId}/${songId}` : `/dashboard/public/${folderId}/${songId}?${isFolderSharing}`;
    const urlForSharing = document.location.origin + urlPath;

    await navigator.clipboard.writeText(urlForSharing);

    setShowPopup(true);
    trackEvent('share', { type: 'copy_link' });

    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    sharingService.getSharedUsersAutofill().then(data => setEmails(data.emails));
  }, []);

  useEffect(() => {
    setInputShareType(formik.values.accessType);
  }, [formik.values.accessType]);

  const excludeEmails: string[] = sharedSongsUsers?.map(sharedUser => sharedUser.email) ?? [];

  const filteredEmails = emails.filter(email => !excludeEmails.includes(email));

  return (
    <>
      {isMobile ? (
        <Drawer
          hideBackdrop
          anchor="bottom"
          open={open}
          onClose={onClose}
          PaperProps={{ sx: { backgroundColor: '#000000', backgroundImage: 'unset', borderTop: '1px solid #8B8B8B4D' } }}
        >
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
              <Stack direction="column" height={'calc(100dvh - 56px)'}>
                <Stack direction="row" p={2} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography fontSize={16} lineHeight={'22px'}>
                    Share song
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation={true}
                    sx={{
                      mr: 1,
                      minWidth: 'unset',
                      width: '40px',
                      height: '40px',
                      boxShadow: 'none',
                      background: 'unset'
                    }}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </Stack>
                <Divider sx={{ background: '#8B8B8B4D' }} />
                <Grid flexDirection="column" flex={1} container alignItems="center" columnSpacing={1} mb={3} width="100%" px={2} pt={3}>
                  <Grid item width="100%">
                    <Autocomplete
                      fullWidth
                      autoComplete={false}
                      options={filteredEmails}
                      inputValue={formik.values.email}
                      value={formik.values.email}
                      clearOnBlur={false}
                      onChange={(event, value, reason) => {
                        formik.setFieldValue('email', reason === 'clear' ? '' : value);
                      }}
                      renderInput={params => (
                        <TextField
                          margin="dense"
                          {...params}
                          placeholder="Email"
                          size="small"
                          autoComplete="off"
                          variant="standard"
                          fullWidth
                          {...getFieldProps('email')}
                          {...errorMessage(touched.email, errors.email)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Stack direction="row" columnGap={1.5} mt={1.5} alignItems={'center'}>
                      <Select
                        {...getFieldProps('accessType')}
                        fullWidth
                        sx={{
                          color: '#ABABAB',
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          },
                          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          },
                          '& .MuiOutlinedInput-input': {
                            p: 0
                          }
                        }}
                      >
                        <MenuItem value={SharedUserRoles.Viewer}>{AccessType.CanView}</MenuItem>
                        <MenuItem value={SharedUserRoles.Downloader}>{AccessType.CanDownload}</MenuItem>
                        <MenuItem value={SharedUserRoles.Editor}>{AccessType.CanEdit}</MenuItem>
                      </Select>
                      <Button sx={{ height: '48px' }} type="submit" size="medium" color="info" variant="contained">
                        Invite
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item width="100%" sx={{ background: 'rgba(139, 139, 139, 0.30)' }} px={1.5} py={1.25} mt={4} borderRadius={0.5}>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                      <Stack flexDirection="row" alignItems="center">
                        <PersonIcon />
                        <Typography ml={1}>{ownerEmail}</Typography>
                      </Stack>
                      <Typography color="#999999">Owner</Typography>
                    </Stack>
                  </Grid>
                  {sharedSongsUsers?.map(sharedUser => (
                    <Grid
                      item
                      width="100%"
                      key={sharedUser.email}
                      sx={{ background: 'rgba(139, 139, 139, 0.30)' }}
                      px={1.5}
                      py={1.25}
                      mt={1}
                      borderRadius={0.5}
                    >
                      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Stack flexDirection="row" alignItems="center">
                          <PersonIcon />
                          <Typography ml={1}>{sharedUser.email.includes('guest-user') ? 'Someone' : sharedUser.email}</Typography>
                        </Stack>
                        <Select
                          MenuProps={{ PaperProps: { sx: { width: '175px' } } }}
                          size="small"
                          defaultValue={sharedUser.accessType}
                          disabled={!isOwner}
                          renderValue={value => {
                            if (SharedUserRoles.Viewer === value) {
                              return AccessType.CanView;
                            }
                            if (SharedUserRoles.Editor === value) {
                              return AccessType.CanEdit;
                            }
                            if (SharedUserRoles.Downloader === value) {
                              return AccessType.CanDownload;
                            }
                          }}
                          onChange={async e => {
                            if (e.target.value === 'delete') {
                              await deleteSharedUser(sharedUser.email, songId ?? '');
                            } else {
                              await handleSetAccessType(sharedUser.email, e.target.value);
                            }
                          }}
                          SelectDisplayProps={{ style: { padding: 0, paddingRight: 32 } }}
                          sx={{
                            p: 0,
                            color: '#ABABAB',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                              border: 0
                            },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 0
                            }
                          }}
                        >
                          <MenuItem value={SharedUserRoles.Viewer}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                              <Typography>{AccessType.CanView}</Typography>
                              {sharedUser.accessType === SharedUserRoles.Viewer && <DoneIcon fontSize={'small'} />}
                            </Stack>
                          </MenuItem>
                          <MenuItem value={SharedUserRoles.Downloader}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                              <Typography>{AccessType.CanDownload}</Typography>
                              {sharedUser.accessType === SharedUserRoles.Downloader && <DoneIcon fontSize={'small'} />}
                            </Stack>
                          </MenuItem>
                          <MenuItem value={SharedUserRoles.Editor}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                              <Typography>{AccessType.CanEdit}</Typography>
                              {sharedUser.accessType === SharedUserRoles.Editor && <DoneIcon fontSize={'small'} />}
                            </Stack>
                          </MenuItem>
                          <MenuItem value="delete" sx={{ color: '#F53C3C' }}>
                            Remove
                          </MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
                <Stack>
                  <Grid
                    container
                    py={3}
                    px={2}
                    spacing={1.5}
                    sx={{ borderTop: '1px solid #8B8B8B4D', borderBottom: '1px solid #8B8B8B4D' }}
                  >
                    <Grid item xs={12}>
                      <Typography>Sharing via the link</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Select
                        onChange={handleChange}
                        value={accessShareType}
                        sx={{
                          color: '#ABABAB',
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          },
                          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          },
                          '& .MuiOutlinedInput-input': {
                            p: 0
                          }
                        }}
                      >
                        <MenuItem value={'invited'}>Only invited can access</MenuItem>
                        <MenuItem value={'anyone'}>Anyone with the link</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={5}>
                      {accessShareType !== 'invited' && (
                        <Select
                          defaultValue={accessTypePublic}
                          onChange={handleAccessChange}
                          sx={{
                            color: '#ABABAB',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                              border: 0
                            },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 0
                            },
                            '& .MuiSelect-select': {
                              py: '0 !important'
                            }
                          }}
                        >
                          <MenuItem value={'VIEWER'}>Can view</MenuItem>
                          <MenuItem value={'CO_OWNER'}>Can download</MenuItem>
                        </Select>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
                <Stack px={2} py={3} direction="row" justifyContent={'flex-end'}>
                  <Button
                    sx={{ width: 'max-content' }}
                    onClick={handleCopySharingLink}
                    size="small"
                    color="secondary"
                    variant="contained"
                    disabled={accessShareType === 'invited'}
                  >
                    <LinkIcon sx={{ marginRight: '4px' }} />
                    {showPopup ? 'Link copied!' : 'Copy link'}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          </FormikProvider>
        </Drawer>
      ) : (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={onClose}
          PaperProps={{ sx: { background: 'rgba(21, 21, 21, 1)', width: '100%', margin: 2 } }}
        >
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
              <DialogCloseButton onClose={onClose} />
              <DialogTitle>Share {songId && 'song'}</DialogTitle>
              <DialogContent dividers>
                <Grid container alignItems="center" columnSpacing={1} sx={{ marginBottom: '20px' }}>
                  <Grid item xs={10} display={'flex'}>
                    <Stack
                      flexDirection="row"
                      sx={{
                        width: '100%',
                        alignItems: 'center',
                        backgroundColor: 'rgba(75, 75, 75, 0.4)',
                        paddingLeft: '12px',
                        height: '33px',
                        border: '1px solid #ABABAB',
                        borderRadius: '4px',
                        marginBottom: '6px'
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        autoComplete={false}
                        options={filteredEmails}
                        inputValue={formik.values.email}
                        value={formik.values.email}
                        clearOnBlur={false}
                        sx={{
                          '& .MuiAutocomplete-inputRoot': {
                            paddingRight: '30px !important'
                          },
                          '& .MuiAutocomplete-endAdornment': {
                            display: 'none'
                          }
                        }}
                        onChange={(event, value, reason) => {
                          formik.setFieldValue('email', reason === 'clear' ? '' : value);
                        }}
                        renderInput={params => (
                          <div style={{ position: 'relative' }}>
                            <TextField
                              margin="dense"
                              {...params}
                              placeholder="Add email"
                              size="small"
                              autoComplete="off"
                              variant="standard"
                              fullWidth
                              {...getFieldProps('email')}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                sx: {
                                  '& .MuiAutocomplete-endAdornment': {
                                    display: 'none'
                                  }
                                }
                              }}
                              sx={{
                                '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
                                  display: 'none'
                                }
                              }}
                            />
                            {touched.email && errors.email && (
                              <FormHelperText
                                error
                                sx={{
                                  position: 'absolute',
                                  top: '100%',
                                  left: 0
                                }}
                              >
                                {errors.email}
                              </FormHelperText>
                            )}
                          </div>
                        )}
                      />

                      <Select
                        {...getFieldProps('accessType')}
                        IconComponent={ArrowDown}
                        sx={{
                          color: '#ABABAB',
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          padding: '0px',
                          '& .MuiSelect-select': {
                            padding: '0px'
                          },
                          '.MuiOutlinedInput-notchedOutline': {
                            border: 0
                          },
                          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          },
                          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 0
                          }
                        }}
                      >
                        <MenuItem value={SharedUserRoles.Viewer}>{AccessType.CanView}</MenuItem>
                        <MenuItem value={SharedUserRoles.Downloader}>{AccessType.CanDownload}</MenuItem>
                        <MenuItem value={SharedUserRoles.Editor}>{AccessType.CanEdit}</MenuItem>
                      </Select>
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      sx={{ height: '33px', marginBottom: '6px', padding: '8px', width: '52px' }}
                      type="submit"
                      color="info"
                      variant="contained"
                    >
                      Invite
                    </Button>
                  </Grid>
                </Grid>
                <Typography mb={2}>People with access</Typography>
                <Grid container flexDirection="column" rowGap={1}>
                  <Grid item sx={{ background: 'rgba(139, 139, 139, 0.30)' }} px={1.5} py={1.25} borderRadius={0.5}>
                    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                      <Stack flexDirection="row" alignItems="center">
                        {user?.picture && (
                          <img src={user.picture} alt="user" style={{ borderRadius: '4px', width: '24px', height: '24px' }} />
                        )}
                        <Typography ml={1}>{user?.name}</Typography>
                      </Stack>
                      <Typography color="#999999">Owner(you)</Typography>
                    </Stack>
                  </Grid>
                  {sharedSongsUsers?.map(sharedUser => (
                    <Grid
                      item
                      key={sharedUser.email}
                      sx={{ background: 'rgba(139, 139, 139, 0.30)' }}
                      px={1.5}
                      py={1.25}
                      borderRadius={0.5}
                    >
                      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Stack flexDirection="row" alignItems="center">
                          <PersonIcon />
                          <Typography ml={1}>{sharedUser.email.includes('guest-user') ? 'Someone' : sharedUser.email}</Typography>
                        </Stack>
                        <Select
                          MenuProps={{ PaperProps: { sx: { width: '175px' } } }}
                          size="small"
                          defaultValue={sharedUser.accessType}
                          disabled={!isOwner}
                          IconComponent={ArrowDown}
                          renderValue={value => {
                            if (SharedUserRoles.Viewer === value) {
                              return AccessType.CanView;
                            }
                            if (SharedUserRoles.Editor === value) {
                              return AccessType.CanEdit;
                            }
                            if (SharedUserRoles.Downloader === value) {
                              return AccessType.CanDownload;
                            }
                          }}
                          onChange={async e => {
                            if (e.target.value === 'delete') {
                              await deleteSharedUser(sharedUser.email, songId ?? '');
                            } else {
                              await handleSetAccessType(sharedUser.email, e.target.value);
                            }
                          }}
                          SelectDisplayProps={{ style: { padding: 0, paddingRight: 32 } }}
                          sx={{
                            p: 0,
                            color: '#ABABAB',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                              border: 0
                            },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 0
                            }
                          }}
                        >
                          <MenuItem value={SharedUserRoles.Viewer}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                              <Typography>{AccessType.CanView}</Typography>
                              {sharedUser.accessType === SharedUserRoles.Viewer && <DoneIcon fontSize={'small'} />}
                            </Stack>
                          </MenuItem>
                          <MenuItem value={SharedUserRoles.Downloader}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                              <Typography>{AccessType.CanDownload}</Typography>
                              {sharedUser.accessType === SharedUserRoles.Downloader && <DoneIcon fontSize={'small'} />}
                            </Stack>
                          </MenuItem>
                          <MenuItem value={SharedUserRoles.Editor}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                              <Typography>{AccessType.CanEdit}</Typography>
                              {sharedUser.accessType === SharedUserRoles.Editor && <DoneIcon fontSize={'small'} />}
                            </Stack>
                          </MenuItem>
                          <MenuItem value="delete" sx={{ color: '#F53C3C' }}>
                            Remove
                          </MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </DialogContent>
              <Typography sx={{ padding: '16px 24px 12px' }}>General access</Typography>
              <DialogContent
                dividers
                sx={{
                  '&.MuiDialogContent-dividers': {
                    borderTop: 'none !important',
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: 0,
                    pb: '20px'
                  }
                }}
              >
                <Select
                  onChange={handleChange}
                  value={accessShareType}
                  IconComponent={ArrowDown}
                  sx={{
                    color: '#ABABAB',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0
                    },
                    '& .MuiSelect-select': {
                      py: '0 !important',
                      pl: 0
                    }
                  }}
                >
                  <MenuItem value={'invited'}>Only invited can access</MenuItem>
                  <MenuItem value={'anyone'}>Anyone with the link</MenuItem>
                </Select>
                {accessShareType !== 'invited' && (
                  <Select
                    defaultValue={accessTypePublic}
                    onChange={handleAccessChange}
                    IconComponent={ArrowDown}
                    sx={{
                      color: '#ABABAB',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '&.MuiSelect-select': {
                        paddingY: '0px !important'
                      },
                      '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        border: 0
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 0
                      },
                      '& .MuiSelect-select': {
                        py: '0 !important'
                      }
                    }}
                  >
                    <MenuItem value={'VIEWER'}>Can view</MenuItem>
                    <MenuItem value={'CO_OWNER'}>Can download</MenuItem>
                  </Select>
                )}
              </DialogContent>

              <DialogActions sx={{ justifyContent: 'flex-start', paddingX: '24px', paddingY: '16px' }}>
                <Button
                  onClick={handleCopySharingLink}
                  sx={{
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    letterSpacing: '-0.4%',
                    paddingLeft: '6px',
                    paddingRight: '8px',
                    paddingY: '5px'
                  }}
                  size="small"
                  color="secondary"
                  variant="contained"
                  disabled={accessShareType === 'invited'}
                >
                  <LinkIcon sx={{ marginRight: '4px', width: '16px', height: '16px' }} />
                  {showPopup ? 'Link copied!' : 'Copy link'}
                </Button>
                {showMsg && (
                  <Typography fontSize={14} fontWeight={400}>
                    Your files will send as soon as upload is complete!
                  </Typography>
                )}
                {showSuccessMsg && (
                  <Stack direction={'row'} mt={1} alignItems={'center'}>
                    <Typography fontSize={14} fontWeight={400} mr={0.5}>
                      Sent
                    </Typography>
                    <Box position={'relative'} height={'14px'} width={'14px'}>
                      <img src={'/assets/success.svg'} height={'14px'} width={'14px'} alt="song" />
                    </Box>
                  </Stack>
                )}
              </DialogActions>
            </Form>
          </FormikProvider>
        </Dialog>
      )}
    </>
  );
}
