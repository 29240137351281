import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Box, Menu, MenuItem, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Song } from '../../../@types/songs';
import DeleteSongDialog from '../../../components/DeleteSongDialog';
import songsService from '../../../services/songsService';
import { useFoldersSelector } from '../../../store/selectors/folders.selector';
import { useSongSelector } from '../../../store/selectors/song.selector';

export type FolderDropDownItemProps = {
  song: Song;
  highlightedSongId: string;
  setHighlightedSongId: (songId: string) => void;
  isSharingFile?: boolean;
  isPublicLocation?: boolean;
};

export default function FolderDropDownItem({
  song,
  setHighlightedSongId,
  highlightedSongId,
  isSharingFile = false,
  isPublicLocation = true
}: FolderDropDownItemProps) {
  const screenWidth = window.innerWidth;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { pathname } = useLocation();
  const { songId } = useParams();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [songIdForDelete, setSongIdForDelete] = useState<string>('');
  const [songIdForRename, setSongIdForRename] = useState<string>('');
  const renameInputRef = useRef<null | HTMLInputElement>(null);

  const { renameSong } = useFoldersSelector();
  const { renameSongOnMainSongPage, currentVersion, songVersions, renameVersionWithoutSongTrack } = useSongSelector();

  const url = isPublicLocation ? `/dashboard/public/${song.folderId}/${song.id}` : `/dashboard/${song.folderId}/${song.id}`;

  useEffect(() => {
    renameInputRef.current?.focus();
  }, [songIdForRename]);

  const handleOpenSongMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (isSharingFile && song.accessType !== 'EDITOR') return;

    setAnchorEl(e.currentTarget);
  };

  const handleCloseSongMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSongDeleteDialog = (songId: string) => {
    handleCloseSongMenu();
    setSongIdForDelete(songId);
  };

  const handleCloseSongDeleteDialog = () => {
    setSongIdForDelete('');
  };

  const handleRename = (songId: string) => {
    handleCloseSongMenu();
    setSongIdForRename(songId);
  };

  const handleRenameOnBlur = () => {
    setSongIdForRename('');
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (
        songId === song.id &&
        currentVersion === songVersions.sort((a, b) => a.versionNumber - b.versionNumber).at(songVersions.length - 1)?.id
      ) {
        renameSongOnMainSongPage(renameInputRef.current?.value ?? song.name, currentVersion);
      }

      const versions = await songsService.getSongVersions(songId!);
      const lastVersionId = versions?.at(versions.length - 1)?.id;

      await renameVersionWithoutSongTrack(renameInputRef.current?.value ?? song.name, lastVersionId);
      await renameSong(song.folderId, song.id, renameInputRef.current?.value ?? song.name);

      handleRenameOnBlur();
    }
  };

  return (
    <Stack
      sx={{ cursor: 'pointer' }}
      draggable={!isSharingFile}
      onClick={() => {
        setHighlightedSongId(song.id);
        navigate(url);
      }}
      direction="row"
      onDragStart={e => {
        if (isSharingFile) return;

        e.dataTransfer.setData('text/plain', JSON.stringify({ songId: song.id, folderId: song.folderId, songName: song.name }));
      }}
      onContextMenu={handleOpenSongMenu}
      spacing={2}
      alignItems="center"
    >
      {songIdForDelete && (
        <DeleteSongDialog
          songId={songIdForDelete!}
          folderId={song.folderId!}
          songName={song?.name!}
          onClose={() => {
            if (pathname.includes(song.id)) {
              navigate('/dashboard');
            }

            handleCloseSongDeleteDialog();
          }}
          open={!!songIdForDelete}
        />
      )}
      <Box
        minWidth="48px"
        width="48px"
        height="48px"
        position="relative"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background:
            highlightedSongId === song.id ? 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 225, 245, 0.85) 88.47%)' : '#4B4B4B66',
          borderRadius: 1
        }}
      >
        <MusicNoteIcon fontSize="medium" sx={{ position: 'absolute' }} />
      </Box>
      <Box>
        {songIdForRename ? (
          <TextField
            size="small"
            defaultValue={song.name}
            inputRef={renameInputRef}
            onKeyDown={handleKeyDown}
            onBlur={handleRenameOnBlur}
            sx={{ fontSize: '14px' }}
          />
        ) : (
          <Tooltip title={song.name}>
            <Typography
              sx={{
                '&.MuiTypography-root': {
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: isMobile ? '210px' : `${Math.round((screenWidth / 800) * 100)}px`,
                  fontSize: '14px'
                }
              }}
            >
              {song.name}
            </Typography>
          </Tooltip>
        )}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseSongMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              mt: 1.5
            }
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={() => handleRename(song.id)}>
          <DriveFileRenameOutlineIcon fontSize="small" sx={{ mr: 1 }} />
          Rename
        </MenuItem>
        <MenuItem sx={{ color: 'red' }} onClick={() => handleOpenSongDeleteDialog(song.id)}>
          <DeleteIcon fontSize="small" sx={{ mr: 1, color: 'red' }} />
          Delete
        </MenuItem>
      </Menu>
    </Stack>
  );
}
