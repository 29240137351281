function writeString(view: DataView, offset: number, string: string) {
  for (let i = 0; i < string.length; i += 1) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

function floatTo16BitPCM(output: DataView, offset: number, input: Float32Array) {
  let writeOffset = offset;
  for (let i = 0; i < input.length; i += 1, writeOffset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(writeOffset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
}

export function encodeWAV(samples: Float32Array, sampleRate: number, mono = false) {
  const buffer = new ArrayBuffer(44 + samples.length * 2);
  const view = new DataView(buffer);

  /* RIFF identifier */
  writeString(view, 0, 'RIFF');
  /* file length */
  view.setUint32(4, 32 + samples.length * 2, true);
  /* RIFF type */
  writeString(view, 8, 'WAVE');
  /* format chunk identifier */
  writeString(view, 12, 'fmt ');
  /* format chunk length */
  view.setUint32(16, 16, true);
  /* sample format (raw) */
  view.setUint16(20, 1, true);
  /* channel count */
  view.setUint16(22, mono ? 1 : 2, true);
  /* sample rate */
  view.setUint32(24, sampleRate, true);
  /* byte rate (sample rate * block align) */
  view.setUint32(28, sampleRate * (mono ? 2 : 4), true);
  /* block align (channel count * bytes per sample) */
  view.setUint16(32, mono ? 2 : 4, true);
  /* bits per sample */
  view.setUint16(34, 16, true);
  /* data chunk identifier */
  writeString(view, 36, 'data');
  /* data chunk length */
  view.setUint32(40, samples.length * 2, true);

  floatTo16BitPCM(view, 44, samples);

  return view;
}

export function mergeBuffers(recBuffers: any[], length: number) {
  const result = new Float32Array(length);
  let offset = 0;

  for (let i = 0; i < recBuffers.length; i += 1) {
    result.set(recBuffers[i], offset);
    offset += recBuffers[i].length;
  }
  return result;
}

export function interleave(inputL: Float32Array, inputR: Float32Array) {
  const length = inputL.length + inputR.length;
  const result = new Float32Array(length);

  let index = 0;
  let inputIndex = 0;

  while (index < length) {
    result[index++] = inputL[inputIndex];
    result[index++] = inputR[inputIndex];
    inputIndex += 1;
  }

  return result;
}

export function exportStem(recBuffersL: any[], recBuffersR: any[], recLength: number, sampleRate: number, type: string, mono = false) {
  let interleaved: Float32Array;

  if (mono) {
    // For mono, only use the left buffer (or right if available)
    const bufferL = mergeBuffers(recBuffersL.length > 0 ? recBuffersL : recBuffersR, recLength);
    interleaved = bufferL;
  } else {
    // For stereo, merge both channels and interleave
    const bufferL = mergeBuffers(recBuffersL, recLength);
    const bufferR = mergeBuffers(recBuffersR, recLength);
    interleaved = interleave(bufferL, bufferR);
  }

  const dataview = encodeWAV(interleaved, sampleRate, mono);

  return new Blob([dataview], { type });
}
